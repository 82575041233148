import Platform from '../src/Main.elm'
import {
  GoogleTagManager,
  Datadog,
  FlagshipIO,
  CookieBot,
  Iubenda,
} from '../integrations'
import * as BrowserIdentification from '../utils/browserIdentification'
import Ports from '../ports'
import * as AppConfig from '../config'
import braintreePayment from './_static/js/braintree-payment'
import Logger from '../utils/logger'
import '../styles/app.scss'

async function enableMocking() {
  // https://github.com/valendres/playwright-msw/blob/main/packages/playwright-msw/README.md#footnotes
  // When running in playwright we must not add the development service worker
  // otherwise they conflict with each other and tests would fail
  if (process.env.NODE_ENV !== 'development' || process.env.PLAYWRIGHT) {
    return
  }

  const { worker } = await import('../e2e/config/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

const appConfig = AppConfig.getInitialConfig()

const datadog = Datadog(appConfig.datadogRUMConfig)

const logger = new Logger({
  datadog,
  isPrivateLogEnvironment: appConfig.isPrivateLogEnvironment,
})

const iubenda = new Iubenda()

const googleTagManager = new GoogleTagManager({
  gtmId: appConfig.gtmId,
  gtmAuth: appConfig.gtmAuth,
  gtmPreview: appConfig.gtmPreview,
  isConsentModeEnabled: AppConfig.isCookieBotEnabled(appConfig),
})

const flagshipIO = new FlagshipIO({
  apiKey: appConfig.flagshipConfig.apiKey,
  envId: appConfig.flagshipConfig.environmentId,
  logger: logger,
  logHitSending: !appConfig.isPrivateLogEnvironment,
  visitorId: BrowserIdentification.getOrCreatePersistentIdentifier(
    false,
    '.helloprima.es'
  ),
  visitorInitialContext: {
    isMobile: BrowserIdentification.isMobileUserAgent(),
  },
})

if (appConfig.privacyConsentProvider === 'IUBENDA') {
  iubenda.initializeIubenda(onIubendaPreferenceExpressed)
}

flagshipIO
  .getFlag('es-fe-funnel-configuration', {})
  .then(initializeApp)
  .catch((err) => {
    logger.error(
      'index.js: Not able to load dynamic flags, initializing without it',
      err
    )
    initializeApp({})
  })

/**
 * Initialize Elm application
 *
 * @param dynamicFlags - Flags that are loaded from a third party provider mainly used for a/b test
 */
async function initializeApp(dynamicFlags) {
  const flags = {
    appEnv: appConfig.appEnv,
    graphqlFederationUrl: appConfig.graphqlFederationUrl,
    assetsBaseUrl: appConfig.assetsBaseUrl,
    homeBaseUrl: appConfig.homeBaseUrl,
    isMobile: BrowserIdentification.isMobileUserAgent(),
    supportedLocales: appConfig.supportedLocales,
    locale: appConfig.defaultLocale,
    dynamicFlags,
    privacyConsentProvider: appConfig.privacyConsentProvider,
    cookieBotId: appConfig.cookieBotId,
    ancillariesEnabled: appConfig.ancillariesEnabled,
  }

  await enableMocking()

  // Initialize the Elm app
  const app = Platform.Elm.Main.init({ flags: JSON.stringify(flags) })

  // Register all ports
  CookieBot.mount(logger, app, onCookieBotConsentUpdated)
  flagshipIO.mount(app)

  iubenda.setApp(app)

  Ports({ googleTagManager, datadog, app })

  // Initialize the braintree SDK and subscribe the required ports to the app
  braintreePayment(app)
}

function onCookieBotConsentUpdated(preferences) {
  if (preferences.statistics) {
    datadog.run()
    flagshipIO.updatePrivacyConsent(true)
  }
}

// Ps. The events pushed to GTM are the same we use on `es-fe`
function onIubendaPreferenceExpressed(preference) {
  const analyticsPurpose = 4
  if (preference.purposes[analyticsPurpose]) {
    datadog.run()
    flagshipIO.updatePrivacyConsent(true)
  }

  dataLayer.push({
    iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
  })

  if (!preference) {
    dataLayer.push({
      event: 'iubenda_preference_not_needed',
    })
    return
  }

  if (preference.consent === true) {
    dataLayer.push({
      event: 'iubenda_consent_given',
    })
  } else if (preference.consent === false) {
    dataLayer.push({
      event: 'iubenda_consent_rejected',
    })
  } else if (preference.purposes) {
    for (var purposeId in preference.purposes) {
      if (preference.purposes[purposeId]) {
        dataLayer.push({
          event: 'iubenda_consent_given_purpose_' + purposeId,
        })
      }
    }
  }
}
